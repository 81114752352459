<template>
  <div class="d-flex flex-row justify-content-around desktop-navbar-container col-12">
    <div class="d-flex flex-column justify-content-center align-items-start col-3">
      <img class="col-5 penrose-logo" src="../../../assets/penrose-logo.png">
    </div>
    <div class="d-flex flex-row justify-content-end align-content-center align-items-center col-9">
      <div class="d-flex flex-column align-items-center col-2 header-text">
        <div @click="routeToPage('dashboard')" v-if="!isTextActive(this.$route, '/dashboard')">
          HOME
        </div>
        <div @click="routeToPage('dashboard')" v-if="isTextActive(this.$route, '/dashboard')" class="active-text">
          HOME
        </div>
      </div>
      <div class="d-flex flex-column align-items-center col-2 header-text">
        <div @click="routeToPage('leave-status')" v-if="!isTextActive(this.$route, '/leave-status')">
          LEAVE STATUS
        </div>
        <div @click="routeToPage('leave-status')" v-if="isTextActive(this.$route, '/leave-status')" class="active-text">
          LEAVE STATUS
        </div>
      </div>
      <div class="d-flex flex-column align-items-center col-2 header-text">
        <div @click="routeToPage('profile')" v-if="!isTextActive(this.$route, '/profile')">
          PROFILE
        </div>
        <div @click="routeToPage('profile')" v-if="isTextActive(this.$route, '/profile')" class="active-text">
          PROFILE
        </div>
      </div>
      <div v-if="userData.Admin" class="d-flex flex-column align-items-center col-2 header-text">
        <div @click="routeToPage('employee-leave')" v-if="!isTextActive(this.$route, '/employee-leave')">
          EMPLOYEE LEAVE
        </div>
        <div @click="routeToPage('employee-leave')" v-if="isTextActive(this.$route, '/employee-leave')" class="active-text">
          EMPLOYEE LEAVE
        </div>
      </div>
<!--      <div v-if="userData.Admin" class="d-flex flex-column align-items-center col-2 header-text">-->
<!--        <div @click="routeToPage('leave-calendar')" v-if="!isTextActive(this.$route, '/leave-calendar')">-->
<!--          LEAVE CALENDAR-->
<!--        </div>-->
<!--        <div @click="routeToPage('leave-calendar')" v-if="isTextActive(this.$route, '/leave-calendar')" class="active-text">-->
<!--          LEAVE CALENDAR-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "MobileNavBar",
  computed: {
    ...mapState(['userData']),
  },
  methods: {
    isTextActive(route, textName) {
      if (route.path === textName) return true
    },
    routeToPage(pageRoute) {
      this.$router.push(`/${pageRoute}`)
    },
  },
}
</script>

<style scoped>
.desktop-navbar-container {
  height: 65px;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
}

.penrose-logo {
  margin-left: 40px;
}

.header-text {
  font-size: 18px;
}

.active-text {
  color: #EE5C36;
}

</style>
