<template>
  <div v-if="!sideBarMenuVisible" class="d-flex flex-row justify-content-around mobile-navbar-container border col-12">
    <div class="d-flex flex-column justify-content-center align-items-start col-6">
      <img class="col-7 penrose-logo" src="../../../assets/penrose-logo.png">
    </div>
    <div @click="burgerMenuClicked()" class="d-flex flex-column justify-content-center align-items-end col-6">
      <img class="col-2 hamburger-menu" src="../../../assets/hamburger-menu.png">
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "MobileNavBar",
  computed: {
    ...mapState(['sideBarMenuVisible'])
  },
  methods: {
    ...mapMutations(['setSidebarMenuVisible']),
    burgerMenuClicked() {
      this.setSidebarMenuVisible(!this.sideBarMenuVisible);
    }
  },
}
</script>

<style scoped>
.mobile-navbar-container {
  height: 65px;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
}

.penrose-logo {
  margin-left: 20px;
}

.hamburger-menu {
  margin-right: 20px;
}
</style>
